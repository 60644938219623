import nav1 from "@/assets/nav1.svg";
import nav2 from "@/assets/nav2.svg";
import nav3 from "@/assets/nav3.svg";
import nav4 from "@/assets/nav4.svg";
import nav5 from "@/assets/nav5.svg";
import nav6 from "@/assets/nav6.svg";
import nav7 from "@/assets/nav7.svg";
import nav8 from "@/assets/nav8.svg";
import nav9 from "@/assets/nav9.svg";

export default [
  {
    module_id: 41,
    module_name: "商品管理",
    icon: nav1,
    index: "2",
    list: [
      {
        module_id: 151,
        module_name: "商品概况",
        path: "/goods/GoodsOverview",
      },
      {
        module_id: 48,
        module_name: "添加商品",
        path: "/goods/AddGood",
      },
      {
        module_id: 42,
        module_name: "商品列表",
        path: "/goods/GoodsList",
      },
      {
        module_id: 180,
        module_name: "商品评价",
        path: "/goods/Evaluation",
      },
    ],
  },
  {
    module_id: 72,
    module_name: "订单管理",
    icon: nav2,
    index: "3",
    list: [
      {
        module_id: 73,
        module_name: "订单列表",
        path: "/order/OrderList",
      },
      {
        module_id: 189,
        module_name: "拼团数据",
        path: "/order/GroupData",
      },
    ],
  },
  {
    module_id: 137,
    module_name: "售后管理",
    icon: nav3,
    index: "4",
    list: [
      {
        module_id: 138,
        module_name: "售后列表",
        path: "/afterSales/afterSalesList",
      },
    ],
  },
  {
    module_id: 89,
    module_name: "运营管理",
    icon: nav4,
    index: "5",
    list: [
      //   {
      //     module_id: 90,
      //     module_name: '发放券管理',
      //     path: '/operation/issueStamps/ruleList',
      //   },
      {
        module_id: 64,
        module_name: "优惠券管理",
        path: "/discounts/discountslist",
      },
      {
        module_id: 101,
        module_name: "活动管理",
        path: "/operation/activityMgmt/activityList",
      },
      {
        module_id: 184,
        module_name: "拼团管理",
        path: "/operation/teamworkManagement/teamworkManagementList",
      },
      {
        module_id: 191,
        module_name: "促销管理",
        path: "/operation/operation/promotion",
      },
    ],
  },
  // {
  //   module_id: 62,
  //   module_name: '优惠券管理',
  //   icon: nav1,
  //   index: '4',
  //   list: [
  //     {
  //       module_id: 64,
  //       module_name: '优惠券列表',
  //       path: '/discounts/discountslist',
  //     },
  //   ],
  // },
  // {
  //   module_id: 126,
  //   module_name: "分销管理",
  //   icon: nav5,
  //   index: "6",
  //   list: [
  //     {
  //       module_id: 127,
  //       module_name: "推广商品管理",
  //       path: "/market/MarketList",
  //     },
  //     {
  //       module_id: 133,
  //       module_name: "提成模板",
  //       path: "/market/TemList",
  //     },
  //   ],
  // },
  {
    module_id: 147,
    module_name: "客服管理",
    icon: nav6,
    index: "7",
    list: [
      {
        module_id: 116,
        module_name: "客服咨询",
        path: "/operation/shopService/ShopService",
      },
    ],
  },
  {
    module_id: 82,
    module_name: "用户管理",
    icon: nav7,
    index: "8",
    list: [
      {
        module_id: 83,
        module_name: "用户列表",
        path: "/user/userList",
      },
    ],
  },
  {
    module_id: 25,
    module_name: "店铺管理",
    icon: nav8,
    index: "9",
    list: [
      {
        module_id: 26,
        module_name: "成员管理",
        path: "/system/user",
      },
      {
        module_id: 27,
        module_name: "岗位管理",
        path: "/system/role",
      },
      {
        module_id: 60,
        module_name: "地址管理",
        path: "/system/selfRaising",
      },
      {
        module_id: 54,
        module_name: "运费模板",
        path: "/system/freightList",
      },
      {
        module_id: 85,
        module_name: "包邮设置",
        path: "/system/freeShipping",
      },
      {
        module_id: 105,
        module_name: "店铺设置",
        path: "/system/storeSettings",
      },
    ],
  },
  {
    module_id: 24,
    module_name: "个人中心",
    icon: nav9,
    index: "10",
    list: [
      {
        module_id: 9,
        module_name: "个人信息",
        path: "/person/personInfo",
      },
    ],
  },
];
