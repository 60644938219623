<template>
  <div class="addDiscount container">
    <el-form
      :model="form"
      style="min-width: 1000px"
      label-width="100px"
      :rules="rules"
      ref="ruleForm"
    >
      <div class="base-set">
        <div class="lineH-box">
          <div class="lineH"></div>
          <div>基本设置</div>
        </div>
        <el-form-item label="排序" prop="categoryId">
          <el-input-number
            v-model="form.sort"
            step-strictly
            :step="1"
            :min="0"
            :max="9999999999"
            :controls="false"
            placeholder="请输入排序值"
          ></el-input-number>
          <span style="margin-left: 10px; color: #999"
            >数字越大，排名越靠前,如果为空，默认排序方式为创建时间</span
          >
        </el-form-item>

        <el-form-item label="优惠券名称" prop="coupon_name">
          <el-input
            v-model="form.coupon_name"
            placeholder="长度不超过10个字"
            maxlength="10"
            :disabled="isEdit"
          ></el-input>
        </el-form-item>

        <el-form-item label="使用时间">
          <el-radio
            v-model="form.time_limit"
            :label="2"
            @change="timeLimitChange"
            :disabled="isEdit"
            >日期</el-radio
          >

          <el-form-item
            label-width="0"
            style="width: 400px; display: inline-block"
            prop="time"
          >
            <el-date-picker
              v-model="form.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :disabled="form.time_limit != 2 || isEdit"
              style="margin-right: 40px"
            >
            </el-date-picker>
          </el-form-item>

          <el-radio
            v-model="form.time_limit"
            :label="1"
            :disabled="isEdit"
            @change="timeLimitChange"
            style="margin-right: 5px"
            >获得后</el-radio
          >

          <el-form-item
            label-width="0"
            style="display: inline-block"
            prop="time_days"
          >
            <el-input-number
              v-model="form.time_days"
              step-strictly
              :step="1"
              :min="1"
              :max="9999999999"
              :controls="false"
              :disabled="form.time_limit != 1 || isEdit"
              style="width: 100px"
            ></el-input-number>
          </el-form-item>
          天有效
        </el-form-item>

        <el-form-item class="coupons-type" label="类型" prop="coupon_type">
          <el-radio-group
            v-model="form.coupon_type"
            @change="couponTypeChange"
            :disabled="isEdit"
          >
            <el-radio :label="1">
              <span>减免券： 减&nbsp;</span>
              <el-form-item
                label-width="0"
                style="display: inline-block"
                prop="relief_enough"
              >
                <el-input-number
                  v-model="form.relief_enough"
                  step-strictly
                  :step="1"
                  :min="1"
                  :max="999"
                  :controls="false"
                  :disabled="form.coupon_type !== 1 || isEdit"
                  placeholder="请输入数字"
                ></el-input-number>
              </el-form-item>
              <span>&nbsp;元商品金额</span>
            </el-radio>
            <el-radio :label="2">
              <span>折扣券： 打&nbsp;</span>
              <el-form-item
                label-width="0"
                style="display: inline-block"
                prop="discount_enough"
              >
                <el-input-number
                  v-model="form.discount_enough"
                  step-strictly
                  :step="0.1"
                  :min="0.1"
                  :max="9.9"
                  :controls="false"
                  :disabled="form.coupon_type !== 2 || isEdit"
                  placeholder="请输入数字"
                ></el-input-number>
              </el-form-item>
              <span>&nbsp;折,最多优惠&nbsp;</span>
              <el-form-item
                label-width="0"
                style="display: inline-block"
                prop="max_price"
              >
                <el-input-number
                  v-model="form.max_price"
                  step-strictly
                  :step="0.01"
                  :min="0"
                  :max="9999999999"
                  :controls="false"
                  :disabled="form.coupon_type !== 2 || isEdit"
                  placeholder="请输入数字"
                ></el-input-number>
              </el-form-item>
              <span>&nbsp;元</span>
              <span style="margin-left: 10px; color: #999">不填时不做限制</span>
            </el-radio>
            <el-radio :label="3">
              <span>运费券： 减&nbsp;</span>
              <el-form-item
                label-width="0"
                style="display: inline-block"
                prop="freight_enough"
              >
                <el-input-number
                  v-model="form.freight_enough"
                  step-strictly
                  :step="1"
                  :min="1"
                  :max="999"
                  :controls="false"
                  :disabled="form.coupon_type !== 3 || isEdit"
                  placeholder="请输入数字"
                ></el-input-number>
              </el-form-item>
              <span>&nbsp;元</span>
              <span style="margin-left: 10px; color: #999"
                >相关商品满门槛时，可使用该优惠券</span
              >
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="领取时间" prop="timeValue">
          <el-date-picker
            v-model="form.timeValue"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            :disabled="isEdit"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="优惠门槛" prop="money">
          <span>单笔满&nbsp;</span>
          <el-input-number
            v-model="form.money"
            step-strictly
            :step="0.01"
            :min="0"
            :max="1000000000000"
            :controls="false"
            :disabled="isEdit"
            placeholder="请输入数字"
          ></el-input-number>
          <span>&nbsp;元</span>
        </el-form-item>

        <el-form-item label="退还方式">
          <el-radio v-model="form.return_type" :disabled="isEdit" :label="1"
            >不退还</el-radio
          >
          <el-radio v-model="form.return_type" :disabled="isEdit" :label="2"
            >下单取消可退还</el-radio
          >
        </el-form-item>

        <el-form-item label="发放总数" prop="total">
          <el-input-number
            v-model="form.total"
            step-strictly
            :step="1"
            :min="-1"
            :max="1000000000000"
            :controls="false"
            placeholder="请输入数字"
          ></el-input-number>
          <span style="margin-left: 10px; color: #999"
            >优惠券总数量，为空时不能领取或发放,-1 为不限制张数</span
          >
        </el-form-item>

        <el-form-item label="用户领取数" prop="max_get_num">
          <el-input-number
            v-model="form.max_get_num"
            step-strictly
            :step="1"
            :min="0"
            :max="99"
            :controls="false"
            placeholder="请输入数字"
          ></el-input-number>
          <span style="margin-left: 10px; color: #999"
            >仅当用户自己领取时，限制的领取数量，手动发放不影响 0为不限制</span
          >
        </el-form-item>

        <el-form-item
          class="promotion-way"
          label="推广方式"
          prop="promotion_way"
        >
          <div class="way_box">
            <div>
              <el-checkbox
                v-model="form.coupon_centre"
                :true-label="1"
                :false-label="0"
                @change="promotionWayChange(1)"
              >
                <span>领券中心（用户可到领券中心领券 ）</span>
              </el-checkbox>
              <!-- <el-form-item
              label-width="0"
              style="display: inline-block"
              prop="show_time"
            >
              <el-date-picker
                v-model="form.show_time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="margin-right: 40px"
                :disabled="!form.coupon_centre"
              >
              </el-date-picker>
            </el-form-item> -->
              <!-- <span style="margin-left: 10px; color: #999"
              >不设置时间则一直上架，上架后用户可到领券中心领券，设置时间后超过该日期后则自动下架</span
            > -->
            </div>
            <div>
              <el-checkbox
                v-model="form.coupon_goods"
                :true-label="1"
                :false-label="0"
                @change="promotionWayChange(2)"
              >
                <span>适用商品（用户可到适用的商品详情领取）</span>
              </el-checkbox>
              <!-- <el-form-item
              label-width="0"
              style="display: inline-block"
              prop="show_goods_time"
            >
              <el-date-picker
                v-model="form.show_goods_time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="margin-right: 40px"
                :disabled="!form.coupon_goods"
              >
              </el-date-picker>
            </el-form-item> -->
              <!-- <span style="margin-left: 10px; color: #999"
              >不设置时间则一直上架，上架后用户可到适用的商品详情领取</span
            > -->
            </div>
            <!-- </el-checkbox-group> -->
          </div>
        </el-form-item>
        <el-form-item label="到期提醒" prop="is_notice">
          <el-radio
            v-model="form.is_notice"
            :label="0"
            :disabled="isEdit"
            @change="noticeDayChange"
            >不提醒</el-radio
          >
          <el-radio
            v-model="form.is_notice"
            :label="1"
            :disabled="isEdit"
            @change="noticeDayChange"
            style="margin-right: 5px"
            >到期前</el-radio
          >
          <el-form-item
            label-width="0"
            style="display: inline-block"
            prop="notice_day"
          >
            <el-input-number
              v-model="form.notice_day"
              step-strictly
              :step="1"
              :min="1"
              :max="99999"
              :controls="false"
              :disabled="form.is_notice != 1 || isEdit"
              style="width: 100px"
            ></el-input-number>
          </el-form-item>
          天提醒
        </el-form-item>
      </div>
      <!-- 使用范围 -->
      <div class="scope-application">
        <div style="display: flex; align-items: center">
          <!-- <span class="title"
                style="margin:0">基本设置</span> -->
          <div class="lineH-box">
            <div class="lineH"></div>
            <div>适用范围</div>
            <span
              style="
                margin-left: 10px;
                color: #999;
                font-size: 14px;
                font-weight: 400;
              "
              >设为部分商品可用时，优惠券仅对选择的商品生效</span
            >
          </div>
        </div>
        <el-form-item
          label="开启部分商品使用"
          prop="open_goods"
          label-width="130px"
        >
          <el-radio-group
            v-model="form.open_goods"
            :disabled="form.coupon_type == 3"
            @change="clickitem('first')"
          >
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="开启不适用商品"
          prop="close_goods"
          label-width="130px"
        >
          <el-radio-group
            v-model="form.close_goods"
            :disabled="form.coupon_type == 3"
            @change="clickitem('second')"
          >
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

      <el-tabs
        v-model="activeName"
        @tab-click="handleClick"
        v-if="form.open_goods !== 0 || form.close_goods !== 0"
      >
        <el-tab-pane label="适用商品" name="first">
          <!-- 选择商品 -->
          <div class="goods-box">
            <el-button
              type="primary"
              style="margin-bottom: 10px"
              @click="chooseGoods"
              :disabled="!form.open_goods"
              >选择商品</el-button
            >
            <div class="table-box">
              <el-table
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%; min-width: 1000px"
                :header-cell-style="{ background: '#F5F5F5' }"
              >
                <el-table-column
                  v-for="item in tableHeaders"
                  :key="item.prop"
                  :prop="item.prop"
                  :label="item.label"
                  :width="item.width"
                  :align="item.align"
                  :fixed="item.fixed"
                >
                  <template slot-scope="scope">
                    <div v-if="item.prop == 'goods_info'" class="goods_info">
                      <div>
                        <img v-if="scope.row.picture == ''" :src="defaultImg" />
                        <img v-else :src="scope.row.picture_str" />
                      </div>
                      <div>
                        {{ scope.row.goods_name }}
                      </div>
                    </div>

                    <div v-else>
                      {{ !scope.row[item.prop] ? "" : scope.row[item.prop] }}
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  label="操作"
                  width="120"
                  align="center"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="medium"
                      @click="deleteGoods(scope.$index)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 分页 -->
            <pagination
              v-show="totalNum > 0"
              :total="totalNum"
              :page.sync="pagination.page"
              :limit.sync="pagination.pageNumber"
              @pagination="getList"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="不适用商品" name="second">
          <!-- 选择商品 -->
          <div class="goods-box">
            <el-button
              type="primary"
              style="margin-bottom: 10px"
              @click="chooseGoods"
              :disabled="!form.close_goods"
              >选择商品</el-button
            >
            <div class="table-box">
              <el-table
                :data="tableCloseData"
                tooltip-effect="dark"
                style="width: 100%; min-width: 1000px"
                :header-cell-style="{ background: '#F5F5F5' }"
              >
                <el-table-column
                  v-for="item in tableHeaders"
                  :key="item.prop"
                  :prop="item.prop"
                  :label="item.label"
                  :width="item.width"
                  :align="item.align"
                  :fixed="item.fixed"
                >
                  <template slot-scope="scope">
                    <div v-if="item.prop == 'goods_info'" class="goods_info">
                      <div>
                        <img v-if="scope.row.picture == ''" :src="defaultImg" />
                        <img v-else :src="scope.row.picture_str" />
                      </div>
                      <div>
                        {{ scope.row.goods_name }}
                      </div>
                    </div>

                    <div v-else>
                      {{ !scope.row[item.prop] ? "" : scope.row[item.prop] }}
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  label="操作"
                  width="120"
                  align="center"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="medium"
                      @click="deleteGoods(scope.$index)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 分页 -->
            <pagination
              v-show="totalNumClose > 0"
              :total="totalNumClose"
              :page.sync="pagination.page"
              :limit.sync="pagination.pageNumber"
              @pagination="getList"
            />
          </div>
        </el-tab-pane>
      </el-tabs>

      <div class="submit flex center align-center">
        <el-button @click="cancel" size="medium">取消</el-button>
        <el-button type="primary" @click="submit" size="medium">提交</el-button>
      </div>
    </el-form>

    <!-- 商品弹框 -->
    <el-dialog title="选择商品" :visible.sync="goodsShow" width="800px">
      <div class="goods-content">
        <div class="searchBox">
          <el-input placeholder="商品关键字" v-model="goods_name" clearable>
          </el-input>
          <el-button
            type="primary"
            style="margin-bottom: 10px"
            @click="getGoodsList"
            >查询</el-button
          >
        </div>
        <div class="table-box" style="border: none">
          <el-table
            :data="goodsData"
            tooltip-effect="dark"
            style="width: 100%"
            height="400"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="全部商品">
              <template slot-scope="scope">
                <div class="item">
                  <img :src="scope.row.picture_str" />
                  <div>
                    {{ scope.row.goods_name }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="销量" prop="real_sales"> </el-table-column>
            <el-table-column label="库存" prop="stock"> </el-table-column>
          </el-table>
          <div class="table-text">已选：{{ goodsSelection.length }} 件商品</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="goodsShow = false">取 消</el-button>
        <el-button type="primary" @click="confirmGoods">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import discounts from "../../api/discounts";
import Pagination from "@/components/Pagination/index";
import _ from "lodash";
export default {
  name: "addDiscount",
  components: {
    Pagination,
  },
  data() {
    let checkTime = (rule, value, callback) => {
      if (value.length == 0 && this.form.time_limit == 2) {
        return callback(new Error("请选择使用时间"));
      }
      callback();
    };
    let checkValueTime = (rule, value, callback) => {
      if (value.length == 0) {
        return callback(new Error("请选择领取时间"));
      }
      callback();
    };
    let noticeDay = (rule, value, callback) => {
      if (!value && this.form.is_notice == 1) {
        return callback(new Error("请输入提醒时间"));
      }
      callback();
    };
    let checkTimeDay = (rule, value, callback) => {
      if (!value && this.form.time_limit == 1) {
        return callback(new Error("请选择有效天数"));
      }
      callback();
    };
    let checkCouponType = (rule, value, callback) => {
      if (!value && this.form.coupon_type == "") {
        return callback(new Error("请选择类型"));
      }
      callback();
    };
    let checkRelief = (rule, value, callback) => {
      if (!value && this.form.coupon_type == 1) {
        return callback(new Error("请输入"));
      }
      callback();
    };
    let checkDiscount = (rule, value, callback) => {
      if (!value && this.form.coupon_type == 2) {
        return callback(new Error("请输入"));
      }
      callback();
    };
    let checkFreight = (rule, value, callback) => {
      if (!value && this.form.coupon_type == 3) {
        return callback(new Error("请输入"));
      }
      callback();
    };
    // let checkshowGoodsTime = (rule, value, callback) => {
    //   let isNull = !value || !value.length;
    //   if (isNull && this.form.coupon_goods == 1) {
    //     return callback(new Error("请选择时间"));
    //   }
    //   callback();
    // };
    // let checkshowTime = (rule, value, callback) => {
    //   let isNull = !value || !value.length;
    //   if (isNull && this.form.coupon_centre == 1) {
    //     return callback(new Error("请选择时间"));
    //   }
    //   callback();
    // };
    let checkMoney = (rule, value, callback) => {
      if (this.form.relief_enough > value) {
        return callback(new Error("优惠门槛不能低于减免卷"));
      }
      callback();
    };
    return {
      module_id_array: window.utils.storage.getter("module_id_array") || [],
      activeName: "first",
      totalNum: 0,
      totalNumClose: 0,
      pagination: {
        page: 1,
        pageNumber: 10,
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      form: {
        sort: undefined,
        coupon_name: "",
        time_limit: 2,
        time: [],
        time_days: undefined,
        coupon_type: "", // 类型
        relief_enough: undefined,
        discount_enough: undefined,
        max_price: undefined,
        freight_enough: undefined,
        money: undefined, // 优惠门槛
        return_type: 1,
        total: undefined,
        max_get_num: 0,
        coupon_centre: "",
        show_time: [],
        coupon_goods: "",
        show_goods_time: [],
        open_goods: 0,
        close_goods: 0,
        timeValue: [],
        is_notice: 0,
        notice_day: undefined,
      },
      rules: {
        coupon_name: [
          { required: true, message: "请输入优惠券名称", trigger: "blur" },
        ],
        time: [{ validator: checkTime, trigger: "blur" }],
        time_days: [{ validator: checkTimeDay, trigger: "blur" }],
        notice_day: [{ validator: noticeDay, trigger: "blur" }],
        timeValue: [
          { required: true, validator: checkValueTime, trigger: "blur" },
        ],
        coupon_type: [
          { required: true, validator: checkCouponType, trigger: "change" },
        ],
        relief_enough: [
          { required: true, validator: checkRelief, trigger: "blur" },
        ],
        discount_enough: [
          { required: true, validator: checkDiscount, trigger: "blur" },
        ],
        freight_enough: [
          { required: true, validator: checkFreight, trigger: "blur" },
        ],
        money: [
          { required: true, validator: checkMoney, trigger: "blur" },
          { required: true, message: "请输入优惠券门槛", trigger: "blur" },
        ],
        // total: [{ required: true, message: '请输入总发放数', trigger: 'blur' }],
        max_get_num: [
          { required: true, message: "请输入用户领取数", trigger: "blur" },
        ],
        // show_goods_time: [
        //   { required: true, validator: checkshowGoodsTime, trigger: 'change' }
        // ],
        // show_time: [
        //   { required: true, validator: checkshowTime, trigger: 'change' }
        // ]
      },
      tableHeaders: [
        {
          label: "商品",
          prop: "goods_info",
          width: "auto",
          align: "center",
          fixed: false,
        },
        {
          label: "规格",
          prop: "sku_name",
          width: "auto",
          align: "center",
          fixed: false,
        },
        {
          label: "价格",
          prop: "price",
          width: "auto",
          align: "center",
          fixed: false,
        },
        {
          label: "会员价",
          prop: "member_price",
          width: "auto",
          align: "center",
          fixed: false,
        },
        {
          label: "库存",
          prop: "stock",
          width: "auto",
          align: "center",
          fixed: false,
        },
      ],
      tableData: [], //适用商品数据
      tableCloseData: [], //不适用商品数据
      groupData: [],
      groupCloseData: [],
      chooseGoodsData: [],
      chooseCloseGoodsData: [],
      goodsData: [],
      goodsShow: false,
      goods_name: "",
      goodsSelection: [],
    };
  },
  computed: {
    isEdit() {
      return this.$route.query.isAdd == 2;
    },
    defaultImg() {
      return require("../../assets/default_img.png");
    },
  },
  watch: {
    chooseGoodsData: {
      handler: function (n, o) {
        this.goodsSelection = n;
      },
      deep: true,
    },
    chooseCloseGoodsData: {
      handler: function (n, o) {
        this.goodsSelection = n;
      },
      deep: true,
    },
    "pagination.pageNumber": {
      handler: function (n, o) {
        this.sortData();
        this.groupGoods();
      },
    },
  },
  created() {
    if (this.isEdit) {
      this.getCouponInfo();
    }
  },
  methods: {
    // 获取详情
    getCouponInfo() {
      let params = {
        coupon_data_id: this.$route.query.coupon_data_id,
      };
      discounts.getCouponInfo(params).then((res) => {
        let { code, data } = res;
        if (code === 0) {
          let obj = JSON.parse(JSON.stringify(data.info));
          obj["timeValue"] = [
            this.dayjs.unix(obj["show_start_time"]).format("YYYY-MM-DD"),
            this.dayjs.unix(obj["show_end_time"]).format("YYYY-MM-DD"),
          ];
          if (obj["show_start_time"] == 0) {
            obj["timeValue"][0] = undefined;
          }
          if (obj["show_end_time"] == 0) {
            obj["timeValue"][1] = undefined;
          }
          switch (obj["time_limit"]) {
            case 1:
              obj["time"] = [];
              break;
            case 2:
              obj["time"] = [
                this.dayjs.unix(obj["time_start"]).format("YYYY-MM-DD"),
                this.dayjs.unix(obj["time_end"]).format("YYYY-MM-DD"),
              ];
              obj["time_days"] = undefined;
              break;
          }

          switch (obj["coupon_type"]) {
            case 1:
              obj["relief_enough"] = obj["enough"];
              break;
            case 2:
              obj["discount_enough"] = obj["enough"];
              obj["max_price"] = obj["max_price"];
              break;
            case 3:
              obj["freight_enough"] = obj["enough"];
              break;
          }

          this.couponTypeChange(obj["coupon_type"]);

          // switch (obj["coupon_centre"]) {
          //   case 1:
          //     obj["show_time"] = [
          //       this.dayjs.unix(obj["show_start_time"]).format("YYYY-MM-DD"),
          //       this.dayjs.unix(obj["show_end_time"]).format("YYYY-MM-DD"),
          //     ];
          //     break;
          //   default:
          //     obj["show_time"] = [];
          //     break;
          // }

          switch (obj["coupon_goods"]) {
            case 1:
              obj["show_goods_time"] = [
                this.dayjs
                  .unix(obj["show_goods_start_time"])
                  .format("YYYY-MM-DD"),
                this.dayjs
                  .unix(obj["show_goods_end_time"])
                  .format("YYYY-MM-DD"),
              ];
              break;
            default:
              obj["show_goods_time"] = [];
              break;
          }
          this.chooseGoodsData = obj["goods_list"] || [];
          this.chooseCloseGoodsData = obj["close_goods_list"] || [];
          this.sortData();
          this.groupGoods();
          this.form = {
            coupon_data_id: obj["coupon_data_id"],
            sort: obj["sort"],
            coupon_name: obj["coupon_name"],
            time_limit: obj["time_limit"],
            time: obj["time"],
            time_days: obj["time_days"],
            coupon_type: obj["coupon_type"], // 类型
            relief_enough: obj["relief_enough"],
            discount_enough: obj["discount_enough"],
            max_price: obj["max_price"] === "0.00" ? 0 : obj["max_price"],
            freight_enough: obj["freight_enough"],
            money: obj["money"], // 优惠门槛
            return_type: obj["return_type"],
            total: obj["total"],
            max_get_num: obj["max_get_num"],
            coupon_centre: obj["coupon_centre"],
            // show_time: obj["show_time"],
            coupon_goods: obj["coupon_goods"],
            show_goods_time: obj["show_goods_time"],
            open_goods: obj["open_goods"],
            close_goods: obj["close_goods"],
            timeValue: obj["timeValue"],
            is_notice: obj["is_notice"],
            notice_day: obj["notice_day"] || undefined,
          };
        }
      });
    },
    // 获取商品弹框列表
    getGoodsList() {
      let params = {
        goods_name: this.goods_name,
        pageNumber: 1000,
      };
      discounts.shopGoodsLists(params).then((res) => {
        let { code, data } = res;
        if (code === 0) {
          this.goodsData = data.list;
          // // 过滤掉列表已经有的数据
          // if (this.tableData) {
          //   this.goodsData = this.goodsData.filter((item) => {
          //     return !this.tableData.find((prop) => {
          //       return prop.goods_id == item.goods_id;
          //     });
          //   });
          // }
          // if (this.tableCloseData) {
          //   this.goodsData = this.goodsData.filter((item) => {
          //     return !this.tableCloseData.find((prop) => {
          //       return prop.goods_id == item.goods_id;
          //     });
          //   });
          // }
        }
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    //点击单选框切换tab
    clickitem(val) {
      if (val == "first" && this.form.open_goods == 1) {
        this.activeName = val;
      }
      if (val == "second" && this.form.close_goods == 1) {
        this.activeName = val;
      }
    },
    //提交表单
    submit() {
      if (this.loading) return;
      let check = false;
      this.$refs.ruleForm.validate((valid) => {
        check = valid;
      });
      if (!check) {
        return;
      }
      this.loading = true;

      let params = Object.assign(this.form);
      params = _.omit(params, [
        "relief_enough",
        "discount_enough",
        "freight_enough",
        "time",
        "show_time",
        "show_goods_time",
        "time_days",
        "timeValue",
      ]);
      params["enough"] = !this.form.relief_enough
        ? !this.form.discount_enough
          ? this.form.freight_enough
          : this.form.discount_enough
        : this.form.relief_enough;
      if (params.time_limit == 2) {
        let time = this.form.time;
        params["time_start"] = this.dayjs(time[0]).format("YYYY-MM-DD");
        params["time_end"] = this.dayjs(time[1]).format("YYYY-MM-DD");
      } else if (params.time_limit == 1) {
        params["time_days"] = this.form.time_days;
      }
      let timeValue = this.form.timeValue;
      params["show_start_time"] = timeValue[0]
        ? this.dayjs(timeValue[0]).format("YYYY-MM-DD")
        : "";
      params["show_end_time"] = timeValue[1]
        ? this.dayjs(timeValue[1]).format("YYYY-MM-DD")
        : "";
      // if (params.coupon_centre == 1) {
      //   let show_time = this.form.show_time;
      //   params["show_start_time"] = show_time[0]
      //     ? this.dayjs(show_time[0]).format("YYYY-MM-DD")
      //     : "";
      //   params["show_end_time"] = show_time[1]
      //     ? this.dayjs(show_time[1]).format("YYYY-MM-DD")
      //     : "";
      // }

      if (params.coupon_goods == 1) {
        let show_goods_time = this.form.show_goods_time;
        params["show_goods_start_time"] = show_goods_time[0]
          ? this.dayjs(show_goods_time[0]).format("YYYY-MM-DD")
          : "";
        params["show_goods_end_time"] = show_goods_time[1]
          ? this.dayjs(show_goods_time[1]).format("YYYY-MM-DD")
          : "";
      }
      params["goods_list"] = this.getGoodsData(this.chooseGoodsData).map(
        (item) => {
          let obj = {
            goods_id: item.goods_id,
            goods_name: item.goods_name,
            sku_id: !item.sku_id ? 0 : item.sku_id,
            sku_name: item.sku_name,
          };
          return obj;
        }
      );
      params["close_goods_list"] = this.getGoodsData(
        this.chooseCloseGoodsData
      ).map((item) => {
        let obj = {
          goods_id: item.goods_id,
          goods_name: item.goods_name,
          sku_id: !item.sku_id ? 0 : item.sku_id,
          sku_name: item.sku_name,
        };
        return obj;
      });
      if (params["time_limit"] == 2 && params["notice_day"] && !this.isEdit) {
        let iscontune =
          this.dayjs(params["time_end"]).diff(params["time_start"], "day") + 1 >
          params["notice_day"];
        if (!iscontune) {
          this.$notify.error({
            title: "到期提醒时间需小于使用时间",
          });
          this.loading = false;
          return;
        }
      }
      if (params["time_limit"] == 1 && params["notice_day"] && !this.isEdit) {
        let iscontune1 = params["time_days"] > params["notice_day"];
        if (!iscontune1) {
          this.$notify.error({
            title: "到期提醒时间需小于使用时间",
          });
          this.loading = false;
          return;
        }
      }
      console.log(params);
      if (!this.isEdit) {
        params["coupon_data_id"] = 0;
        discounts
          .createCoupon(params)
          .then((res) => {
            let { code } = res;
            if (code === 0) {
              this.$message.success("添加成功");
              this.loading = false;
              this.$router.push("/discounts/discountsList");
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        discounts
          .editCoupon(params)
          .then((res) => {
            let { code } = res;
            if (code === 0) {
              this.$message.success("编辑成功");
              this.loading = false;
              this.$router.push("/discounts/discountsList");
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },

    // 商品弹框
    chooseGoods() {
      this.goodsShow = true;
      this.getGoodsList();
    },
    // 获取选择商品列表
    getList() {
      let isNull = this.groupData[this.pagination.page - 1];
      if (!isNull) {
        this.tableData = this.groupData[this.pagination.page - 2];
      } else {
        this.tableData = this.groupData[this.pagination.page - 1];
      }
      let isNullclose = this.groupCloseData[this.pagination.page - 1];
      if (!isNullclose) {
        this.tableCloseData = this.groupCloseData[this.pagination.page - 2];
      } else {
        this.tableCloseData = this.groupCloseData[this.pagination.page - 1];
      }
    },
    // 选择商品
    handleSelectionChange(val) {
      console.log(123, val);
      this.goodsSelection = val;
    },
    // 商品分组
    groupGoods() {
      this.totalNum = this.chooseGoodsData.length;
      this.totalNumClose = this.chooseCloseGoodsData.length;
      this.groupData = _.chunk(
        this.getGoodsData(this.chooseGoodsData),
        this.pagination.pageNumber
      );
      this.getList();
      this.groupCloseData = _.chunk(
        this.getGoodsData(this.chooseCloseGoodsData),
        this.pagination.pageNumber
      );
      this.getList();
    },
    //去重
    getGoodsData(arr) {
      // 缓存用于记录
      const cache = [];
      for (const t of arr) {
        // 检查缓存中是否已经存在
        if (
          cache.find((c) => c.goods_id === t.goods_id && c.sku_id === t.sku_id)
        ) {
          // 已经存在说明以前记录过，现在这个就是多余的，直接忽略
          continue;
        }
        // 不存在就说明以前没遇到过，把它记录下来
        cache.push(t);
      }
      // 记录结果就是过滤后的结果
      return cache;
    },
    // 数据排序
    sortData() {
      if (this.activeName == "first") {
        let list = JSON.parse(JSON.stringify(this.chooseGoodsData));
        let arr = [];
        // console.log(list);
        for (let i = 0; i < list.length; i++) {
          if (list[i].hasOwnProperty("filter_id")) {
            // list[i].seq = i;
            list[i]["filter_id"] = i;
            arr.push(list[i]);
          } else if (!list[i].open_spec) {
            // list[i].seq = i;
            list[i]["filter_id"] = i;
            arr.push(list[i]);
          } else {
            for (let j = 0; j < list[i].item.length; j++) {
              // list[i]['item'][j].seq = j + i;
              list[i]["item"][j]["filter_id"] = list[i]["item"][j].sku_id;
              arr.push(list[i]["item"][j]);
            }
          }
        }
        // console.log(arr);
        this.chooseGoodsData = _.uniqBy(arr, "filter_id");
        // console.log(this.chooseGoodsData);
      } else if (this.activeName == "second") {
        let list = JSON.parse(JSON.stringify(this.chooseCloseGoodsData));
        let arr = [];
        // console.log(list);
        for (let i = 0; i < list.length; i++) {
          if (list[i].hasOwnProperty("filter_id")) {
            // list[i].seq = i;
            list[i]["filter_id"] = i;
            arr.push(list[i]);
          } else if (!list[i].open_spec) {
            // list[i].seq = i;
            list[i]["filter_id"] = i;
            arr.push(list[i]);
          } else {
            for (let j = 0; j < list[i].item.length; j++) {
              // list[i]['item'][j].seq = j + i;
              list[i]["item"][j]["filter_id"] = list[i]["item"][j].sku_id;
              arr.push(list[i]["item"][j]);
            }
          }
        }
        // console.log(arr);
        this.chooseCloseGoodsData = _.uniqBy(arr, "filter_id");
        // console.log(this.chooseCloseGoodsData);
      }
    },
    // 确认商品
    confirmGoods() {
      this.goodsShow = false;
      if (this.activeName == "first") {
        console.log(" this.goodsSelection", this.goodsSelection);
        if (!this.chooseGoodsData.length) {
          this.chooseGoodsData = this.goodsSelection;
        } else {
          this.chooseGoodsData.push(...this.goodsSelection);
        }
        console.log(" chooseGoodsData", this.chooseGoodsData);
        this.sortData();
        this.groupGoods();
      } else if (this.activeName == "second") {
        if (!this.chooseCloseGoodsData.length) {
          this.chooseCloseGoodsData = this.goodsSelection;
        } else {
          this.chooseCloseGoodsData.push(...this.goodsSelection);
        }
        this.sortData();
        this.groupGoods();
      }
    },
    // 删除商品
    deleteGoods(index) {
      if (this.activeName == "first") {
        this.chooseGoodsData.splice(index, 1);
        this.sortData();
        this.groupGoods();
      } else if (this.activeName == "second") {
        this.chooseCloseGoodsData.splice(index, 1);
        this.sortData();
        this.groupGoods();
      }
    },
    // 推广方式改变
    promotionWayChange(type) {
      switch (type) {
        case 1:
          if (!this.form.coupon_centre) {
            this.form.show_time = [];
          }
          break;
        case 2:
          if (!this.form.coupon_goods) {
            this.form.show_goods_time = [];
          }
          break;
      }
    },
    // 类型改变
    couponTypeChange(type) {
      console.log(123, type);
      if (type == 3) {
        this.form.open_goods = 0;
        this.form.close_goods = 0;
      }
      let strArr = [
        "relief_enough",
        "discount_enough",
        "max_price",
        "freight_enough",
      ];
      strArr.map((item, index) => {
        if (index + 1 !== type) {
          this.form[item] = undefined;
        }
        if (type === 2) {
          this.form["max_price"] = undefined;
        }
      });
    },
    // 使用时间
    timeLimitChange(type) {
      switch (type) {
        case 1:
          this.form.time = [];
          break;
        case 2:
          this.form.time_days = undefined;
          break;
      }
    },
    noticeDayChange(type) {
      switch (type) {
        case 0:
          this.form.notice_day = undefined;
          break;
        case 1:
          break;
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style lang='scss' scoped>
.lineH-box {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #606266;
  font-weight: 700;
  margin-bottom: 20px;
  .lineH {
    width: 3px;
    height: 18px;
    background: #4974f5;
    border-radius: 21px;
    margin-right: 8px;
  }
}
.el-input,
.el-input-number,
.el-cascader {
  width: 250px;
  margin-right: 5px;
}

.submit {
  margin: 30px 0;
}
.addDiscount {
  .base-set {
    .coupons-type {
      .el-radio-group {
        display: flex;
        flex-direction: column;
        .el-radio {
          margin-bottom: 20px;
          .el-form-item {
            margin: 0;
          }
        }
      }
    }
    .promotion-way {
      .way_box {
        display: flex;
      }
      .el-checkbox {
        // display: flex;
        // align-items: center;
        .el-form-item {
          margin: 0;
        }
      }
    }
  }

  .title {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .goods-box {
    .goods_info {
      display: flex;
      align-items: center;
      img {
        width: 80px;
        height: 80px;
        margin-right: 20px;
      }
    }
  }
}
.goods-content {
  .table-box {
    .el-table::before {
      height: 0;
    }
    .item {
      display: flex;
      align-items: center;
      img {
        width: 80px;
        height: 80px;
        margin-right: 20px;
      }
    }
  }
}
.table-text {
  padding: 10px 10px;
}
</style>

